import React from 'react'

import './spacer.css'

function Spacer() {
  return (
    <div className='spacer'></div>
  )
}

export default Spacer